@use "styles/colors.module.scss" as colors;
@use "styles/media.module.scss" as media;

html {
  font-size: 62.5%;
}

body {
  background-color: colors.$ci-gray-1;
  color: colors.$cubic-black;
  font-family: "Roboto", sans-serif;

  margin: 0;
  padding: 0;
}

textarea {
  font-family: inherit;
}

* {
  box-sizing: border-box;
  outline: none;
}

a {
  color: initial;
  text-decoration: none;
}

.App {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  row-gap: 16px;

  @include media.xl {
    row-gap: 32px;
  }

  @include media.xxl {
    row-gap: 46px;
  }
}

.animated-child {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

address {
  font-style: inherit;
}

.pswp__img {
  transform: scale(1.5);
}

.pswp__caption__center {
  text-align: center !important;
}
