.enter,
.appear {
  opacity: 0;
  transform: translateY(30px);
}

.enterActive,
.appearActive {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 400ms ease 200ms, transform 400ms ease 200ms;
}
