@mixin xs() {
  @media (min-width: 0px) {
    @content;
  }
}

@mixin sm() {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin md() {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin xxl() {
  @media (min-width: 2430px) {
    @content;
  }
}
