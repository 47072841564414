$cubic-black: #3c3c3b;
$cubic-red: #e63020;
$cubic-yellow: #ffb511;
$cubic-blue: #5b72c3;
$cubic-green: #5bc397;
$cubic-white: #ffffff;

$ci-gray-1: #f3f4f9;
$ci-gray-2: #ebecf1;
$ci-gray-3: #e2e3ea;
$ci-gray-4: #d9dae4;
$ci-gray-5: #bcbeca;
$ci-gray-6: #8b8da0;

$ci-secondary-gray-1: #f3f4f9ce;

$ci-secondary-blue1: #d6dcf0;
$ci-secondary-blue2: #3e52ac;
$ci-secondary-blue3: #2a3b98;

$ci-secondary-red1: #f9cbc7;
$ci-secondary-red2: #d21c11;
$ci-secondary-red3: #b80f09;
$ci-semitransparent-red: rgba(255, 0, 0, 0.04);

$ci-secondary-yellow1: #ffb4112c;
$ci-secondary-yellow2: rgba(255, 180, 17, 0.04);

$ci-semitransparent-green: rgba(91, 195, 151, 0.04);

:export {
  cubicBlack: $cubic-black;
  cubicRed: $cubic-red;
  cubicYellow: $cubic-yellow;
  cubicBlue: $cubic-blue;
  cubicGreen: $cubic-green;
  cubicWhite: $cubic-white;

  ciGray1: $ci-gray-1;
  ciGray2: $ci-gray-2;
  ciGray3: $ci-gray-3;
  ciGray4: $ci-gray-4;
  ciGray5: $ci-gray-5;
  ciGray6: $ci-gray-6;

  ciSecondaryBlue1: $ci-secondary-blue1;
  ciSecondaryBlue2: $ci-secondary-blue2;
  ciSecondaryBlue3: $ci-secondary-blue3;

  ciSecondaryRed1: $ci-secondary-red1;
  ciSecondaryRed2: $ci-secondary-red2;
  ciSecondaryRed3: $ci-secondary-red3;
}
